<template>
  <v-container fluid fill-height class="grey darken-3">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="black" light flat>
            <v-toolbar-title class="white--text">Wprowadź email</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-alert v-if="message" type="error">
                {{ message }}
              </v-alert>
              <v-alert v-else-if="success" type="success">
                {{ success }}
              </v-alert>
              <v-row align="center" justify="center" v-if="!success">
                <v-col>
                  <v-text-field v-model="email" label="Email" name="email" type="email" />
                </v-col>
                <v-card-actions>
                  <v-btn @click="recover" color="amber accent-3" class="white--text" :x-large="true"
                    >Zatwierdź</v-btn
                  >
                </v-card-actions>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'recovery',
    data: () => ({
      email: null,
      message: null,
      success: null,
    }),
    methods: {
      recover: function () {
        this.message = null;
        this.success = null;
        axios({
          url: this.$store.state.url + '/api/recovery',
          data: {
            email: this.email,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.message = null;
              this.success = resp.data.message;
              setTimeout(() => {
                this.$router.push('/login');
              }, 5000);
            } else {
              this.message = resp.data.message;
            }
          })
          .catch();
      },
    },
    created() {
      if (this.$store.state.token) this.$router.push('/');
    },
  };
</script>
